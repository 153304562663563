<template>
  <v-row
    ref="dataviz"
    class="customReportPlaceholder ma-3"
    :style="currentStyleRules"
  >
    <tableau-viz
      id="tableauViz"
      ref="tableau"
      :src="currentSrc"
      :token="jwt"
      :device="device"
      :height="`${currentHeight}px`"
      :width="`${currentWidth}px`"
      :style="currentStyleRules"
      toolbar="top"
    />
  </v-row>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const { mapState: mapAuthState } = createNamespacedHelpers('auth')
const { mapGetters: mapToolsGetters, mapState: mapToolsState } = createNamespacedHelpers('tools')

export default {
  name: 'ConnectedApp',
  props: {
    customReport: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      container: null,
      device: 'default',
      jwt: null,
      options: {},
      TABLEAU_CONFIG: {
        origin: 'https://10ay.online.tableau.com',
        paths: {
          metrics: '/#/site/oao/metrics',
          site: '/#/site/oao/views',
          views: '/t/oao/views',
        },
        query: ':embed=y&:showAppBanner=false&:display_count=n&:showVizHome=y&:origin=viz_share_link',
        scripts: {
          embedding: {
            loaded: false,
            path: '/javascripts/api/tableau.embedding.3.latest.js',
          },
          metric: {
            loaded: false,
            path: '/javascripts/api/tableau.metric.1.latest.min.js',
          },
        },
      },
      url: null,
      viz: null,
      vizScript: null,
      workbook: null,
    }
  },
  computed: {
    ...mapAuthState({
      credential: (state) => state.credential,
    }),
    ...mapToolsGetters(['reportKey']),
    ...mapToolsState({
      reportId: (state) => state.reportId,
      reportName: (state) => state.reportName,
    }),
    worksheet() {
      return !this.workbook ? null : this.workbook.getActiveSheet()
    },
    currentSrc() {
      const { customReport, TABLEAU_CONFIG: { origin, paths } } = this
      const { metrics, site, views } = paths
      const { tableau: { path, tabs, type = 'views' } } = customReport || { tableau: {} }

      const urlMap = {
        metrics: () => `${origin}${metrics}/${path}`,
        site: () => `${origin}${site}/${path}`,
        views: () => `${origin}${views}/${path}/${tabs.default}`,
      }

      return urlMap[type]()
    },
    currentStyles() {
      return this.customReport.style || {}
    },
    currentWidth() {
      const { width = '300' } = this.currentStyles || {}
      return width
    },
    currentHeight() {
      const { height = '300' } = this.currentStyles || {}
      return height
    },
    currentStyleRules() {
      const { currentHeight, currentWidth } = this
      return `width: ${currentWidth}px; height: ${currentHeight}px;`
    },
  },
  watch: {
    customReport(newValue, oldValue) {
      if (newValue && (!oldValue || oldValue.href !== newValue.href)) this.setup()
    },
  },
  mounted() {
    this.load('metric')
    this.load('embedding')
  },
  beforeDestroy() {
    this.teardown()
  },
  methods: {
    init() {
      if (this.viz) {
        this.teardown()
      }

      return this.setup()
    },
    teardown() {
      if (!this.viz) return false
      return this.viz.dispose()
    },
    error({ message = 'Error Unknown' }) {
      const error = { message, status: 'error' }

      this.$store.commit('tools/snackbar', true)
      this.$store.commit('tools/snack', error)

      return { error }
    },
    onFirstInteractive() {
      if (!this.viz) return false

      this.workbook = this.viz.getWorkbook()
      this.activeSheet = this.workbook.getActiveSheet()

      return this.viz
    },
    makeOptions() {
      return {
        hideTabs: false,
        hideToolbar: false,
        toolbarPosition: 'Top',
        onFirstInteractive: this.onFirstInteractive,
      }
    },
    async setToken() {
      const set = async () => {
        const token = await this.$store.dispatch('auth/getTableauToken')

        if (token && !token.error) {
          this.jwt = token.message
        } else {
          this.error(token)
        }

        return token
      }

      return this.jwt || set()
    },
    async setup(loading) {
      await this.setToken()

      if (loading && !this.customReport) return false

      const { customReport } = this
      const { tableau } = customReport || { tableau: {} }
      const { path } = tableau || {}

      if (!path) {
        const { reportKey } = this
        const errorUnknownReport = () => (!tableau && {
          code: 404,
          layer: 'app/location',
          message: 'Unknown Custom Report',
          metadata: { reportKey },
        })
        const errorMisconfiguredReport = () => (!path && {
          code: 412,
          layer: 'app/data',
          message: 'Misconfigured Custom Report',
          metadata: { reportKey },
        })
        const error = errorUnknownReport() || errorMisconfiguredReport() || {
          code: 400,
          layer: 'app',
          message: 'Custom Reports Error',
          metadata: { reportKey },
        }

        this.error(error)

        return customReport
      }

      return this
    },
    load(scriptKey) {
      const currentScript = this.TABLEAU_CONFIG.scripts[scriptKey]
      const { path } = currentScript
      const scriptPath = `${this.TABLEAU_CONFIG.origin}${path}`

      currentScript.loaded = this.loadScript(scriptPath, 'module')

      this.$nextTick(() => {
        currentScript.loaded.addEventListener('load', () => this.setup('loading'))
      })
    },
    loadScript(src, type) {
      const script = document.createElement('script')

      script.defer = true // @TODO Determine if script.defer is necessary.
      script.setAttribute('src', src)
      script.setAttribute('type', type)

      this.vizScript = document.head.appendChild(script)

      return script
    },

    updateTab(path) {
      this.workbook.activateSheetAsync(path)
    },
    switchToDashboard() {
      const { path, tabs } = this
      this.updateTab(`${path}/${tabs.dashboard}`)
    },
    switchToReportAll() {
      const { path, tabs } = this
      this.updateTab(`${path}/${tabs.reportAll}`)
    },
  },
}
</script>
